import logo from './logo.svg';
import './App.css';
import { GlobeOutline } from 'react-ionicons'


function App() {
  return (
    <div className="App">
      <a target='_blank' href="https://www.linkedin.com/in/sivitilanea-alexandre-38a31a2b9/">
         <h1>Sivitilanea Luísa  Brandão Alexandre  
         <GlobeOutline color={'#ffff'}  height="50px" width="50px"/>
         </h1>
      </a>
    </div>
  );
}

export default App;
